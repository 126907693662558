import servicesUtils from '../utils/service'

export const menuServices = {
    async getMenuList() {
        try {
            const url = `${servicesUtils.resourceUrl('menuList')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getMenuList err: ${err}`)
            throw err
        }
    },

    async getMenu(menuUrl) {
        try {
            const url = `${servicesUtils.resourceUrl('menu')}`.replace('${url}', menuUrl)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getMenu err: ${err}`)
            throw err
        }
    },
    async getMenuContentList(menuUrl, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('menuContentList')}`.replace('${url}', menuUrl) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getMenuContentList err: ${err}`)
            throw err
        }
    },

    async getContent(contentId) {
        try {
            const url = `${servicesUtils.resourceUrl('menuContent')}`.replace('${contentId}', contentId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getContent err: ${err}`)
            throw err
        }
    },
}