<template>
    <div class="quiz main-content">
        <div class="row no-gutters align-center mt-4">
            <div class="pr-4" v-if="currentStep > 1">
                <v-btn
                    class="rounded-pill"
                    outlined
                    color="#777"
                    title="上一題"
                    value="上一題"
                    aria-label="上一題"
                    @click="previousStep()"
                >
                    <v-icon small>mdi-arrow-left</v-icon>上一題
                </v-btn>
            </div>
            <div class="col">
                <v-progress-linear
                    class="rounded-pill"
                    :value="((currentStep - 1) / questions.length) * 100"
                    color="#91a93b"
                    background-color="#eee"
                    height="10px"
                ></v-progress-linear>
            </div>
            <div class="pl-4">
                {{ Math.round(((currentStep - 1) / questions.length) * 100) }}%
            </div>
        </div>
        <v-stepper v-model="currentStep" class="grey lighten-5">
            <v-stepper-items v-for="(q, qIndex) in questions" :key="qIndex">
                <v-stepper-content :step="qIndex + 1">
                    <div class="row no-gutters">
                        <div class="col-12 text-center">
                            <h3 class="mb-2">第{{ qIndex + 1 }}題</h3>
                            <p class="quest-title" v-html="q.title"></p>
                        </div>
                        <div class="row no-gutters col-12 align-center">
                            <div class="col-12 col-sm-7 col-md-7 col-lg-7 pa-2">
                                <img class="quest-img" :src="q.img" />
                            </div>
                            <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                                <v-list nav dense class="grey lighten-5">
                                    <v-list-item-group color="#20780c">
                                        <v-list-item
                                            v-for="(o, oIndex) in q.options"
                                            :key="oIndex"
                                            @click="nextStep(o.grade)"
                                        >
                                            <v-list-item-icon>
                                                <div class="no">
                                                    {{
                                                        optionNumberToLetter(
                                                            oIndex + 1
                                                        )
                                                    }}
                                                </div>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="o.text"
                                                ></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                        </div>
                    </div>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>

<script>
import { toNumberLetter } from "@/utils/common";
import { quizServices } from "@/services/quiz.js";

export default {
    name: "Quiz",
    components: {},
    data() {
        return {
            userId: -1,
            quizId: -1,
            quizTitle: "",
            selectedOption: -1,
            currentStep: 0,
            questions: [],
            answers: [],
            isLock: false,
        };
    },
    mounted() {},
    async created() {
        let id = this.$route.params.id;
        this.userId = sessionStorage.getItem("user_id");
        if (!this.userId) {
            this.$router.push("/scale/:id/instruction".replace(":id", quiz.id));
        }
        const res = await quizServices.getQuizQuest(id);
        const quiz = res.data;
        if (!quiz.id) {
            this.$router.push("/scale");
        }
        if (quiz.id != sessionStorage.quiz_id) {
            this.$router.push("/scale/:id/".replace(":id", quiz.id));
        }
        this.$store.commit("setQuizTitle", quiz.title);
        //this.$root.$emit('scaleTitle', quiz.title);

        this.quizId = quiz.id;
        this.quizTitle = quiz.title;
        this.questions = quiz.quest;
        this.$nextTick(() => {
            this.currentStep += 1;
        });
    },
    methods: {
        calculateProgress() {},
        optionNumberToLetter(n) {
            return toNumberLetter(n);
        },
        previousStep() {
            this.answers.pop();
            this.currentStep--;
        },
        async nextStep(options) {
            this.isLock = true;
            this.answers.push(options);

            if (this.currentStep < this.questions.length) {
                setTimeout(() => {
                    this.selectedOption = -1;
                    this.currentStep++;
                    this.isLock = false;
                }, 700);
            } else {
                await this.saveData();
            }
        },
        async saveData() {
            let data = {
                ans: JSON.stringify(this.answers),
                user_id: sessionStorage.user_id,
            };
            let result = await quizServices.saveQuizData(this.quizId, data);

            if (result.success) {
                this.$router.push("/scale/result/" + result.data);
            }
        },
    },
};
</script>

<style scoped>
/*.quiz {
    max-width: 1000px;
    margin: 0 auto;
}*/
.quiz h3 {
    color: #20780c;
}

.quest-title {
    color: #444444;
    font-size: 1.1rem;
}

.quest-title >>> b,
strong {
    color: black;
}
.quest-img {
    border-radius: 40px;
    width: 100%;
}

.no {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-style: solid;
    border-color: #b8c8b6;
    border-radius: 4px;
    color: #b8c8b6;
    padding: 3px;
}
.v-sheet {
    box-shadow: none !important;
}
.v-list-item {
    border-style: solid;
    border-color: #b8c8b6;
    border-radius: 4px;
}

.v-list-item__title {
    color: #20780c !important;
    font-size: 1.1rem !important;
    line-height: 1.1rem !important;
}
/*
.theme--light.v-stepper{
    background-color: var(--v-background-base)!important;
}
.theme--light.v-list{
    background-color: var(--v-background-base)!important;
}*/
.theme--light.v-list-item {
    background-color: #ffffff;
}
</style>