import servicesUtils from '../utils/service'

export const adminUserServices = {
    async getAdminUerList() {
        const url = `${servicesUtils.resourceUrl('consoleAdminUser')}`
        const data = await servicesUtils.get(url)
        return data
    },
    async saveOrUpdateUser(id, account, name, password, role) {
        const url = `${servicesUtils.resourceUrl('consoleAdminUser')}`+'/'+id
        const data = await servicesUtils.post(url, {
            account: account,
            name: name,
            password: password,
            role: role,
        })
        return data
    },

    async deleteUser(id) {
        const url = `${servicesUtils.resourceUrl('consoleAdminUser')}`+'/'+id
        const data = await servicesUtils.delete(url)
        return data
    },
    async getRoleList() {
        const url = `${servicesUtils.resourceUrl('consoleAdminUserRoleList')}`
        const data = await servicesUtils.get(url)
        return data
    },
}