import servicesUtils from '../utils/service'

export const educationServices = {
    async getEducation(d) {
        try {
            const url = `${servicesUtils.resourceUrl('education')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get education: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },
}