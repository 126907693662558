<template>
    <div id="apa">
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large
        ></v-breadcrumbs>
        <h2 border="bottom" class="mb-2">{{ funcTitle }}</h2>
        <v-card outlined class="py-3 px-5">
            <v-data-table
                :headers="headers"
                :items="desserts"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="light-green darken-3"
                            outlined
                            small
                            @click="newItem()"
                        >
                            <v-icon small>mdi-plus</v-icon> 新增選單
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="editItem(item)"
                    >
                        <v-icon small> mdi-cog </v-icon>
                        設定
                    </v-btn>
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        color="error"
                        @click="deleteItem(item)"
                    >
                        <v-icon small> mdi-delete </v-icon>
                        刪除
                    </v-btn>

                    <v-btn
                        v-if="item.contentType == '5'"
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="goSubMenu(item)"
                    >
                        <v-icon small> mdi-eye </v-icon>
                        查看子選單
                    </v-btn>

                    <v-btn
                        v-else-if="item.contentType == '4'"
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="goEditContent(item)"
                    >
                        <v-icon small> mdi-pencil </v-icon>
                        編輯網頁內容
                    </v-btn>

                    <v-btn
                        v-else-if="item.contentType == '1'"
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="editLink(item)"
                    >
                        <v-icon small> mdi-pencil </v-icon>
                        編輯連結
                    </v-btn>

                    <v-btn
                        v-else-if="item.contentType != '6'"
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="goContents(item)"
                    >
                        <v-icon small> mdi-pencil </v-icon>
                        查看內容列表
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">{{ menuFormTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="menuForm" v-model="valid" lazy-validation>
                            <v-row>
                                <!--
                                <v-col cols="12" v-show="editedItem.id != ''">
                                    <v-text-field
                                        v-model="editedItem.id"
                                        label="選單id"
                                        readonly
                                        filled
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                -->
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedItem.name"
                                        label="選單名稱"
                                        hide-details="auto"
                                        :rules="nameRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="editedItem.description"
                                        label="描述"
                                        outlined
                                        hide-details="auto"
                                        :rules="descriptionRules"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedItem.url"
                                        label="網址"
                                        hide-details="auto"
                                        :rules="urlRules"
                                        :readonly="
                                            editedItem.contentType == 6 ||
                                            editedItem.displayOrder == 0
                                        "
                                        :filled="
                                            editedItem.contentType == 6 ||
                                            editedItem.displayOrder == 0
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    v-show="editedItem.contentType != 6"
                                >
                                    <v-select
                                        v-model="editedItem.contentType"
                                        :items="contentTypeList"
                                        label="選單類型"
                                        item-text="title"
                                        item-value="id"
                                        hide-details="auto"
                                        :rules="contentTypeRule"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <v-select
                                        v-model="editedItem.status"
                                        :items="statusList"
                                        label="是否顯示"
                                        item-text="title"
                                        item-value="id"
                                        hide-details="auto"
                                        :rules="statusRule"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    v-show="
                                        !(
                                            editedItem.displayOrder == 0 &&
                                            editedItem.url == 'index'
                                        )
                                    "
                                >
                                    <v-text-field
                                        v-model="editedItem.displayOrder"
                                        label="顯示順序"
                                        hide-details="auto"
                                        :rules="displayOrderRules"
                                        type="number"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn
                        color="light-green darken-2 white--text"
                        @click="saveMenu"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="400px" persistent>
            <v-card>
                <v-card-title class="justify-center"
                    >你確定要刪除項目「{{ editedItem.name }}」嗎？</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDelete" :disabled="buttonLoading"
                        >取消</v-btn
                    >
                    <v-btn
                        color="error"
                        @click="deleteItemConfirm"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                        >刪除</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="linkEditDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">編輯連結</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="linkForm" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="outsideLink"
                                        label="連結名稱"
                                        hide-details="auto"
                                        :rules="outsideLinkRules"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="linkEditDialog = false"
                        :disabled="buttonLoading"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="light-green darken-2 white--text"
                        @click="saveLink"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <template>
            <div class="text-center">
                <v-snackbar
                    v-model="snackBar.on"
                    :timeout="snackBar.timeout"
                    :color="snackBar.type"
                    right
                >
                    {{ snackBar.text }}
                </v-snackbar>
            </div>
        </template>
    </div>
</template>

<script>
import { menuConsoleServices } from "@/services/menuConsole.js";
import { menuServices } from "@/services/menu.js";

export default {
    name: "MenuList",
    components: {},
    data() {
        return {
            mainFuncTitle: "前台選單列表",
            breadcrumbsItems: [],
            valid: true,
            refId: "",
            refMenuName: "",
            dialog: false,
            linkEditDialog: false,
            dialogDelete: false,
            statusList: [
                {
                    id: 1,
                    title: "顯示",
                },
                {
                    id: 2,
                    title: "隱藏",
                },
            ],
            contentTypeList: [
                {
                    id: "1",
                    title: "外部連結",
                },
                {
                    id: "2",
                    title: "文字列表",
                },
                {
                    id: "3",
                    title: "圖文列表",
                },
                {
                    id: "4",
                    title: "網頁",
                },
                {
                    id: "5",
                    title: "子選單",
                },
                {
                    id: "6",
                    title: "客製功能",
                    disabled: true,
                },
            ],
            headers: [
                {
                    text: "選單名稱",
                    sortable: false,
                    value: "name",
                },
                { text: "網址", value: "url" },
                { text: "種類", value: "contentTypeTitle" },
                { text: "狀態", value: "statusTitle" },
                { text: "排序", value: "displayOrder" },
                { text: "建立日期", value: "createdAt" },
                { text: "功能", value: "actions", sortable: false },
            ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                id: "",
                name: "",
                description: "",
                url: "",
                status: "",
                statusTitle: "",
                contentType: "",
                contentTypeTitle: "",
                displayOrder: "",
            },
            defaultItem: {
                id: "",
                name: "",
                description: "",
                url: "",
                status: "",
                contentType: "",
                displayOrder: "",
            },
            nameRules: [(v) => !!v || "必須填寫帳號"],
            descriptionRules: [(v) => !!v || "必須填寫描述"],
            urlRules: [
                (v) => !!v || "必須填寫網址",
                (v) =>
                    !this.desserts.find(
                        (item) => item.url == v && item.id != this.editedItem.id
                    ) || "網址重複",
            ],
            statusRule: [(v) => !!v || "必須選擇是否顯示"],
            contentTypeRule: [(v) => !!v || "必須選擇選單類型"],
            displayOrderRules: [
                (v) => /^-?\d+$/.test(v) || "必須是數字",
                (v) =>
                    !(this.editedIndex == -1 && v > this.desserts.length + 1) ||
                    "顯示順序必須小於" + (this.desserts.length + 2),
                (v) =>
                    !(this.editedIndex != -1 && v > this.desserts.length) ||
                    "顯示順序數字必須小於" + (this.desserts.length + 1),
                (v) => v > 0 || "顯示順序數字必須大於0",
            ],
            editMenuId: -1,
            outsideLink: "",
            outsideLinkRules: [(v) => this.urlValidate(v) || "網址格式不正確"],
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            buttonLoading: false,
        };
    },
    computed: {
        menuFormTitle() {
            return this.editedIndex === -1 ? "新增選單" : "修改選單";
        },
        funcTitle() {
            return this.refMenuName != ""
                ? `${this.refMenuName}`
                : `${this.mainFuncTitle}`;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    async created() {
        this.refId = this.$route.params.mid;
        if (this.refId != null) {
            const menuRes = await menuConsoleServices.getMenu(this.refId);
            if (menuRes.data.contentType != "5") {
                this.$router.push("/404");
            }
            this.refMenuName = menuRes.data.name;
            this.breadcrumbsItems.push(
                {
                    text: this.mainFuncTitle,
                    disabled: false,
                    href: "/console/menu",
                },
                {
                    text: this.refMenuName,
                    disabled: true,
                    href: "",
                }
            );
        } else {
            this.breadcrumbsItems.push({
                text: this.mainFuncTitle,
                disabled: true,
                href: "",
            });
        }
        await this.initialData();
    },

    methods: {
        async initialData() {
            let payload = {};
            if (this.refId != null) {
                payload["ref_id"] = this.refId;
            }
            const res = await menuConsoleServices.getMenuList(payload);
            let menuList = res.data;
            this.desserts.length = 0;
            for (let menu of menuList) {
                this.desserts.push({
                    id: menu.id,
                    name: menu.name,
                    description: menu.description,
                    url: menu.url,
                    contentType: menu.contentType,
                    contentTypeTitle: this.contentTypeList.find(
                        (type) => type.id == menu.contentType
                    ).title,
                    status: menu.status,
                    statusTitle: this.statusList.find(
                        (type) => type.id == menu.status
                    ).title,
                    displayOrder: menu.displayOrder,
                    createdAt: menu.createdAt,
                });
            }
        },
        newItem() {
            this.editedItem.displayOrder = this.desserts.length;
            this.valid = true;
            this.dialog = true;
        },
        editItem(item) {
            this.valid = true;
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.savingLock();
            await menuConsoleServices.deleteMenu(this.editedItem.id);

            this.showAlertMsg("success", "刪除成功");
            this.savingUnLock();

            this.closeDelete();
            await this.initialData();
        },

        close() {
            this.$refs.menuForm.reset();
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        async saveMenu() {
            if (this.$refs.menuForm.validate()) {
                this.savingLock();

                await menuConsoleServices.saveOrUpdateMenu(this.editedItem.id, {
                    name: this.editedItem.name,
                    description: this.editedItem.description,
                    url: this.editedItem.url,
                    content_type: this.editedItem.contentType,
                    display_order: this.editedItem.displayOrder,
                    status: this.editedItem.status,
                    ref_id:
                        this.refId != null ? this.refId : this.editedItem.refId,
                });

                this.showAlertMsg("success", "儲存成功");
                this.savingUnLock();

                this.$refs.menuForm.reset();
                this.close();
                await this.initialData();
            }
        },

        async editLink(item) {
            this.editMenuId = item.id;
            const returnContent = await menuServices.getMenuContentList(
                item.url,
                {}
            );
            let data = returnContent.data;
            if (data.length != 0) {
                this.outsideLink = data.content;
            } else {
                this.outsideLink = "";
            }
            this.linkEditDialog = true;
        },

        async saveLink() {
            if (this.$refs.linkForm.validate()) {
                this.savingLock();
                await menuConsoleServices.saveOrUpdateMenuLink(
                    this.editMenuId,
                    {
                        link: this.outsideLink,
                    }
                );

                this.showAlertMsg("success", "儲存成功");

                this.$refs.linkForm.reset();
                this.linkEditDialog = false;
                this.savingUnLock();
            }
        },
        urlValidate(url) {
            const pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                "i"
            ); // fragment locator
            return !!pattern.test(url);
        },

        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
        goSubMenu(item) {
            this.$router.push("/console/menu/:mid".replace(":mid", item.id));
        },
        goContents(item) {
            this.$router.push(
                "/console/menu/:mid/contents".replace(":mid", item.id)
            );
        },
        async goEditContent(item) {
            this.editMenuId = item.id;
            const returnContent = await menuServices.getMenuContentList(
                item.url,
                {}
            );
            let data = returnContent.data;
            this.$router.push(
                "/console/menu/:mid/content/:cid"
                    .replace(":mid", item.id)
                    .replace(":cid", (data.id ? data.id: ''))
            );
        },
    },
};
</script>

<style scoped>
</style>