const toNumberLetter = (num) => {
    // 1 = A
    // 2 = B
    // 3 = C
    let letter = String.fromCharCode(num + 64);
    return letter;
}

module.exports = {
    toNumberLetter,
}