import servicesUtils from '../utils/service'

export const loginServices = {
    async login(d) {
        const url = `${servicesUtils.resourceUrl('login')}`
        const data = await servicesUtils.post(url, d)
        return data
    },
    async logout() {
        const url = `${servicesUtils.resourceUrl('logout')}`
        const data = await servicesUtils.post(url, {})
        return data
    },
    async getAdminName() {
        const url = `${servicesUtils.resourceUrl('adminName')}`
        const data = await servicesUtils.get(url, {})
        return data
    },
    async updatePwd(oldPwd, newPwd) {
        const url = `${servicesUtils.resourceUrl('consoleChangePwd')}`
        const data = await servicesUtils.post(url, {
            old_pwd: oldPwd,
            new_pwd: newPwd,
        })
        return data
    },
}