<template>
    <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    class="w-100"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: ['uuid', 'chartData'],
  data() {
      return {
        chartOptions: {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1.5,
            indexAxis: 'y',
            scales: {
                x: {
                    min: 0,
                    max: 100,
                },
            },
            animation: {
                onComplete: function () {}
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                datalabels: {
                    anchor: 'start',
                    align: 'end',
                    color: '#555',
                    formatter: function(value, context) {
                        return value + '分 ' + context.dataset.labels[context.dataIndex];
                    }
                }
            }
        }
      }
  }
}
</script>
