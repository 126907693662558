<template>
    <div id="apa">
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large
        ></v-breadcrumbs>

        <h2 border="bottom" class="mb-2">{{ mainFuncTitle }}</h2>
        <v-card outlined class="py-3 px-5">
            <v-data-table
                :headers="headers"
                :items="users"
                hide-default-footer
                disable-sort
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="light-green darken-3"
                            outlined
                            small
                            @click="newItem()"
                        >
                            <v-icon small>mdi-plus</v-icon> 新增使用者
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="editItem(item)"
                    >
                        <v-icon small> mdi-pencil </v-icon>
                        編輯
                    </v-btn>
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        color="error"
                        @click="deleteItem(item)"
                    >
                        <v-icon small> mdi-delete </v-icon>
                        刪除
                    </v-btn>
                </template>
            </v-data-table>
            <v-container class="max-width">
                <v-pagination
                    v-model="page"
                    class="my-4"
                    :length="totalPage"
                    circle
                    @input="getPageData"
                    color="light-green"
                ></v-pagination>
            </v-container>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="400px" persistent>
            <v-card>
                <v-card-title class="justify-center"
                    >你確定要刪除項目「{{ editedItem.name }}」嗎？</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDelete" :disabled="buttonLoading"
                        >取消</v-btn
                    >
                    <v-btn
                        color="error"
                        @click="deleteItemConfirm"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                        >刪除</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editUserdialog" max-width="500px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">{{ userFormTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="userForm" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-alert type="error" v-if="errorMsg">{{errorMsg}}</v-alert>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedItem.account"
                                        label="帳號"
                                        hide-details="auto"
                                        :rules="accountRules"
                                        :readonly="!!editedItem.id"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        v-model="editedItem.name"
                                        label="姓名"
                                        hide-details="auto"
                                        :rules="nameRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="editedItem.role"
                                        :items="roles"
                                        item-text="name"
                                        item-value="id"
                                        label="角色"
                                        single-line
                                        :role="roleRules"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="newPwd"
                                        :append-icon="
                                            showNewPwd
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        :type="showNewPwd ? 'text' : 'password'"
                                        label="新密碼"
                                        :rules="newPwdRules"
                                        @click:append="showNewPwd = !showNewPwd"
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="reNewPwd"
                                        :append-icon="
                                            showReNewPwd
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        :type="
                                            showReNewPwd ? 'text' : 'password'
                                        "
                                        label="再輸入一次新密碼"
                                        :rules="reNewpwdRules"
                                        @click:append="
                                            showReNewPwd = !showReNewPwd
                                        "
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close" :disabled="buttonLoading">
                        取消
                    </v-btn>
                    <v-btn
                        color="light-green darken-2 white--text"
                        @click="saveUser"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                    >
                        儲存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template>
            <div class="text-center">
                <v-snackbar
                    v-model="snackBar.on"
                    :timeout="snackBar.timeout"
                    :color="snackBar.type"
                    right
                >
                    {{ snackBar.text }}
                </v-snackbar>
            </div>
        </template>
    </div>
</template>

<script>
import { adminUserServices } from "@/services/adminUser.js";

export default {
    name: "MenuList",
    components: {},
    data() {
        return {
            mainFuncTitle: "使用者列表",
            breadcrumbsItems: [],
            valid: true,
            loading: true,
            page: -1,
            totalPage: -1,
            dialogDelete: false,
            editUserdialog: false,
            headers: [
                {
                    text: "帳號",
                    sortable: false,
                    value: "account",
                },
                {
                    text: "姓名",
                    sortable: false,
                    value: "name",
                },
                { text: "建立日期", value: "createdAt" },
                { text: "修改日期", value: "updatedAt" },
                { text: "功能", value: "actions", sortable: false },
            ],
            users: [],
            editedIndex: -1,
            editedItem: {
                id: "",
                account: "",
                name: "",
                role: "",
                createdAt: "",
                updatedAt: "",
            },
            isAccountReadOnly: false,
            userFormTitle: "",
            newPwd: "",
            reNewPwd: "",
            role: null,
            newPwdErrorMsg: "",
            reNewPwdErrorMsg: "",
            accountRules: [(v) => !!v || "必須填寫帳號"],
            roleRules: [(v) => !!v || "必須選擇角色"],
            nameRules: [(v) => !!v || "必須填寫姓名"],
            newPwdRules: [
                (v) => !(!this.editedItem.id && !v) || "必須填寫密碼",
                (v) =>
                    !v ||
                    !(
                        (!/[a-z]+/.test(v) && !/[A-Z]+/.test(v)) ||
                        !/\d+/.test(v) ||
                        v.length < 8
                    ) ||
                    "密碼必須大於8碼且包含英文字母及數字",
            ],
            reNewpwdRules: [
                (v) => !(!this.editedItem.id && !v) || "必須再填寫新密碼1",
                (v) =>
                    !this.newPwd ||
                    (!!this.newPwd && !!v) ||
                    "必須再填寫新密碼2",
                (v) => !(!!this.newPwd && v != this.newPwd) || "與新密碼不同",
            ],
            showNewPwd: false,
            showReNewPwd: false,
            roles: [],
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            buttonLoading: false,
            errorMsg: "",
        };
    },
    computed: {},
    watch: {
        userFormTitle() {
            return this.editedIndex === -1 ? "新增使用者" : "修改使用者";
        },
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    async created() {
        const roleRes = await adminUserServices.getRoleList();
        const roleData = roleRes.data;
        for (let role of roleData) {
            this.roles.push({
                id: role.id,
                name: role.name,
            });
        }
        this.breadcrumbsItems.push({
            text: "後台使用者管理",
            disabled: true,
            href: ``,
        });

        this.breadcrumbsItems = this.breadcrumbsItems.reverse();
        this.page = 1;
        this.getPageData(1);
    },

    methods: {
        async getPageData(page) {
            let payload = {
                page: page,
            };

            const res = await adminUserServices.getAdminUerList(payload);
            let data = res.data;
            this.totalPage = data.totalPage;
            this.loading = false;

            let userList = data.list;
            this.users.length = 0;
            if (userList.length != 0) {
                for (let user of userList) {
                    this.users.push({
                        id: user.id,
                        account: user.account,
                        name: user.name,
                        role: user.role,
                        createdAt: user.createdAt,
                        updatedAt: user.updatedAt,
                    });
                }
            } else if (page == 1) {
                this.users.length = 0;
            } else {
                this.page = this.page - 1;
                if (page != 0) {
                    this.getPageData(this.page);
                }
            }
        },
        newItem() {
            this.errorMsg = '';
            this.isAccountReadOnly = false;
            this.valid = true;
            this.editUserdialog = true;
        },
        editItem(item) {
            this.errorMsg = '';
            this.isAccountReadOnly = true;
            this.valid = true;
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editUserdialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.savingLock();
            await adminUserServices.deleteUser(this.editedItem.id);

            this.showAlertMsg("success", "刪除成功");
            this.savingUnLock();

            this.closeDelete();
            await this.getPageData(this.page);
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async saveUser() {
            this.errorMsg = "";
            if (this.$refs.userForm.validate()) {
                this.savingLock();
                let res = await adminUserServices.saveOrUpdateUser(
                    this.editedItem.id ? this.editedItem.id : "",
                    this.editedItem.account,
                    this.editedItem.name,
                    this.newPwd,
                    this.editedItem.role
                );
                if (typeof res.data.return_code == "undefined") {
                    this.showAlertMsg(
                        "success",
                        (this.editedItem.id ? "更新" : "新增") + "成功"
                    );
                    this.$refs.userForm.reset();
                    if (!this.editedItem.id) {
                        this.page = 1;
                    }
                    this.editUserdialog = false
                    this.getPageData(this.page);
                } else {
                    this.errorMsg = "帳號已存在";
                }

                this.savingUnLock();
            }
        },
        close() {
            this.$refs.userForm.reset();
            this.editUserdialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
    },
};
</script>

<style scoped>
</style>