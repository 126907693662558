import servicesUtils from '../utils/service'

export const sysFunctionServices = {
    async getFunctionList() {
        try {
            const url = `${servicesUtils.resourceUrl('sysFunction')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getFunctionList err: ${err}`)
            throw err
        }
    },
}