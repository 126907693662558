<template>
    <div id="app">
        <v-app>
            <AppBar/>
            <Drawer/>
            <v-main>
                <v-container class="px-5 py-4">
                    <router-view :key="$route.path"></router-view>
                </v-container>
            </v-main>
        </v-app>
    </div>
</template>

<script>
import AppBar from "@/components/console/AppBar.vue";
import Drawer from "@/components/console/Drawer.vue";

export default {
    name: "ConsoleLayout",

    components: {
        Drawer,
        AppBar,
    },
};
</script>
