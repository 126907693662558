import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import PortalLayout from './layout/PortalLayout.vue'
import DefaultLayout from './layout/DefaultLayout.vue'
import ConsoleLayout from './layout/ConsoleLayout.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store'

import ckeditor from "@ckeditor/ckeditor5-vue2";

Vue.config.productionTip = false

Vue.component('defaultLayout', DefaultLayout)
Vue.component('portalLayout', PortalLayout)
Vue.component('consoleLayout', ConsoleLayout)

Vue.use(Vuelidate)
Vue.use(ckeditor);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
