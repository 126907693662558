<template>
    <v-navigation-drawer
        id="core-navigation-drawer"
        v-model="drawer"
        :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
        :expand-on-hover="expandOnHover"
        :src="barImage"
        :permanent="$vuetify.breakpoint.mdAndUp"
        app
        width="260"
        v-bind="$attrs"
    >
        <v-list dense nav>
            <v-list-item>
                <v-list-item-avatar
                    class="align-self-center ma-0 mr-2"
                    color="white"
                    contain
                >
                    <v-img src="/favicon.ico" max-height="30" />
                </v-list-item-avatar>

                <v-list-item-content class="pa-0">
                    <v-list-item-title style="line-height: unset"
                        ><v-btn
                            text
                            plain
                            class="white--text text-h5 pa-0 ma-0"
                            :to="getConsoleIndexUrl"
                            >管理平台</v-btn
                        >
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider class="mb-2" />

        <v-list expand nav>
            <template v-for="item in menu">
                <v-list-item v-if="item.children.length == 0" :key="item.id">
                    <v-list-item-title>
                        <v-btn text :to="item.url" class="w-100 justify-start"
                            ><v-icon small class="mr-1">{{
                                item.displayIcon
                            }}</v-icon
                            >{{ item.title }}</v-btn
                        >
                    </v-list-item-title>
                </v-list-item>
                <v-list-group :key="item.id" :value="false" v-else>
                    <template v-slot:activator>
                        <v-list-item-title>
                            <v-btn text
                                ><v-icon small class="mr-1">{{
                                    item.displayIcon
                                }}</v-icon
                                >{{ item.title }}</v-btn
                            >
                        </v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="subMenu in item.children"
                        :key="subMenu.id"
                    >
                        <v-list-item-title class="pl-3">
                            <v-btn text :to="subMenu.url"
                                >ww{{ subMenu.title }}</v-btn
                            >
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider class="mb-1" />
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { sysFunctionServices } from "@/services/sysFunction.js";
import { mapState, mapGetters } from "vuex";
export default {
    name: "Menu",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        menu: [],
    }),
    async created() {
        const funcRes = await sysFunctionServices.getFunctionList();
        let functMap = funcRes.data;
        let menuList = functMap.functions;
        let subMenuMap = functMap.sub_function_map;

        let currentUrl = this.$router.currentRoute.path;

        let redirectUrl = "";
        let firstFuncUrl = "";

        let funcList = [];
        for (let i = 0; i < menuList.length; i++) {
            let m = menuList[i];
            let menu = this.parseMenu(m);

            let subMenuList = subMenuMap[m.id];
            if (subMenuList != null) {
                for (let smInd = 0; smInd < subMenuList.length; smInd++) {
                    let subMenu = this.parseMenu(subMenuList[smInd]);
                    menu.children.push(subMenu);

                    if (firstFuncUrl == "") {
                        firstFuncUrl = subMenu.url;
                    }
                    if (currentUrl == subMenu.url) {
                        redirectUrl = currentUrl;
                    }

                    funcList.push(subMenu.url);
                }
            }
            if (menu.status != 0) {
                this.menu.push(menu);
            }
            
            if (menu.children.length == 0) {
                if (firstFuncUrl == "") {
                    firstFuncUrl = menu.url;
                }
                if (currentUrl == menu.url) {
                    redirectUrl = currentUrl;
                }
                funcList.push(menu.url);
            }
        }

        if (redirectUrl == "") {
            redirectUrl = firstFuncUrl;
        }
        this.$store.dispatch("consoleIndexUrl", firstFuncUrl);
        localStorage.setItem("consoleFuncList", JSON.stringify(funcList));

        if (
            redirectUrl != currentUrl &&
            currentUrl.indexOf(redirectUrl) == -1
        ) {
            this.$router.push(firstFuncUrl);
        }
    },
    computed: {
        ...mapGetters(["getConsoleIndexUrl"]),
        ...mapState(["barColor", "barImage"]),
        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            },
        },
        computedItems() {
            return this.items.map(this.mapItem);
        },
        profile() {
            return {
                avatar: true,
            };
        },
    },
    methods: {
        mapItem(item) {
            return {
                ...item,
                children: item.children
                    ? item.children.map(this.mapItem)
                    : undefined,
            };
        },
        parseMenu(m) {
            let menu = {
                id: m.id,
                url: m.url,
                title: m.name,
                description: m.description,
                displayIcon: m.displayIcon,
                status: m.status,
                children: [],
            };
            return menu;
        },
    },
};
</script>

<style scoped></style>
