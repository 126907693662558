<template>
    <div>
        <div class="main-content row justify-center mt-4">
            <v-col class="col-12 col-sm-11 col-md-10 col-lg-8">
                <div v-html="content" class="ck-content"></div>
            </v-col>
        </div>
    </div>
</template>

<script>

import { menuServices } from "@/services/menu.js";

export default {
    name: "Index",
    components: {},
    data() {
        return {
            content: '',
        };
    },
    async created() {
        window.scrollTo(0, 0);
        this.$store.commit("setQuizTitle", '');
        //this.$root.$emit('scaleTitle', '');
        const returnContent = await menuServices.getMenuContentList('index',{});
        let data = returnContent.data;
        this.content = data.content;
    },
};
</script>

<style scoped>
img {
    max-width: 100% !important;
    height: auto !important;
}
</style>