<template>
    <div v-show="showPage">
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">{{ menuTitle }}</h2>
        </div>
        <div class="main-content row justify-center mt-4">
            <div
                v-if="contentType == '2'"
                class="col-12 col-md-12 col-lg-10 mx-auto"
            >
                <v-list two-line color="light">
                    <v-list-item-group active-class="green--text">
                        <template v-for="(item, index) in items">
                            <v-list-item
                                :key="item.title"
                                @click="goContent(item.id)"
                            >
                                <template>
                                    <v-list-item-action>
                                        <v-chip
                                            class="ma-2"
                                            color="primary"
                                            outlined
                                            pill
                                        >
                                            {{ item.createdAt }}
                                        </v-chip>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.title"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>

                            <v-divider :key="index"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </div>
            <template v-else v-for="item in items">
                <v-col
                    :key="item.title"
                    class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"
                >
                    <a
                        href="javascript:void(0)"
                        class="text-decoration-none"
                        :title="item.title"
                        @click="goContent(item.id)"
                    >
                        <v-card class="mx-auto content-card" max-width="350px">
                            <v-card-subtitle class="pb-0">
                                {{ item.createdAt }}
                            </v-card-subtitle>
                            <v-card-title class="pt-0 pb-1">
                                {{ item.title }}
                            </v-card-title>
                            <v-img :src="item.previewImg"></v-img>
                        </v-card>
                    </a>
                </v-col>
            </template>
            <v-col cols="12">
                <v-container class="max-width">
                    <v-pagination
                        v-model="page"
                        class="my-4"
                        :length="totalPage"
                        circle
                        @input="getPageData"
                        color="light-green"
                    ></v-pagination>
                </v-container>
            </v-col>
        </div>
    </div>
</template>

<script>
import { menuServices } from "@/services/menu.js";

export default {
    name: "ContentTitleList",
    components: {},
    props: ["menuUrl", "menuTitle", "contentType"],
    data() {
        return {
            page: -1,
            totalPage: -1,
            items: [],
            showPage: false,
        };
    },
    async mounted() {
        this.page = 1;
        this.getPageData(1);
    },
    methods: {
        async getPageData(page) {
            let payload = {
                page: page,
            };
            const returnContent = await menuServices.getMenuContentList(
                this.menuUrl,
                payload
            );
            let data = returnContent.data;

            if (this.contentType == '1') {
                location.href=returnContent.data.content;
                return;
            }
            let contentList = data.list;
            let itemList = [];
            for (let content of contentList) {
                itemList.push({
                    id: content.id,
                    title: content.title,
                    previewImg: content.previewImg,
                    createdAt: content.createdAt,
                    link: "/${menuUrl}/${contentId}"
                        .replace("${menuUrl}", this.menuUrl)
                        .replace("${contentId}", content.id),
                });
            }
            this.items = itemList;
            this.totalPage = data.totalPage;
            this.showPage = true;
        },
        goContent(id) {
            this.$router.push(
                "/${menuUrl}/${contentId}"
                    .replace("${menuUrl}", this.menuUrl)
                    .replace("${contentId}", id)
            );
        },
    },
};
</script>

<style scoped>
.content-card {
    cursor: pointer;
}
.content-card:hover,
.content-card:hover > .v-card__subtitle {
    color: #fff !important;
    background-color: #91a93b !important;
}
img {
    height: auto !important;
    max-width: 100% !important;
}
</style>