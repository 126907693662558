<template>
    <div>
        
        <ContentList v-if="contentType == '2' || contentType == '3' || contentType == '1'" :menuUrl="menuUrl" :menuTitle="menuTitle" :contentType="contentType"/>
        <ContentHtml v-if="contentType == '4'" :menuUrl="menuUrl" :menuTitle="menuTitle" :contentType="contentType" :contentId="contentId"/>
    </div>
</template>

<script>

import ContentList from '@/components/ContentList.vue'
import ContentHtml from '@/components/ContentHtml.vue'
import { menuServices } from "@/services/menu.js";

export default {
    name: "Content",
    components: {ContentList, ContentHtml},
    data() {
        return {
            menuUrl: '',
            menuTitle: '',
            contentType: '0',
            contentId: -1,
        };
    },
    async created() {
        window.scrollTo(0, 0);
        this.$store.commit("setQuizTitle", '');
        //this.$root.$emit('scaleTitle', '');
        let menuUrl = this.$route.params.menuUrl;
        let contentId = this.$route.params.contentId;

        const data = await menuServices.getMenu(menuUrl);
        if (!data.data.name) {
            this.$router.push("/404")
        }
        this.menuTitle = data.data.name;
        this.menuUrl = menuUrl;
        if (typeof contentId != 'undefined') {
            this.contentId = contentId;
            this.contentType = '4';
        } else {
            this.contentType = data.data.contentType;
            this.contentId = -1;
        }
        
    },
};
</script>

<style scoped>
</style>