<template>
    <div id="apa">
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large
        ></v-breadcrumbs>

        <h2 border="bottom" class="mb-2">{{ funcTitle }}</h2>
        <v-card outlined class="py-3 px-5">
            <v-data-table
                :headers="headers"
                :items="desserts"
                hide-default-footer
                disable-sort
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="light-green darken-3"
                            outlined
                            small
                            @click="newItem()"
                        >
                            <v-icon small>mdi-plus</v-icon> 新增內容
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        outlined
                        @click="editItem(item)"
                    >
                        <v-icon small> mdi-pencil </v-icon>
                        編輯
                    </v-btn>
                    <v-btn
                        small
                        class="mr-2 mt-1"
                        color="error"
                        @click="deleteItem(item)"
                    >
                        <v-icon small> mdi-delete </v-icon>
                        刪除
                    </v-btn>
                </template>
            </v-data-table>
            <v-container class="max-width">
                <v-pagination
                    v-model="page"
                    class="my-4"
                    :length="totalPage"
                    circle
                    @input="getPageData"
                    color="light-green"
                ></v-pagination>
            </v-container>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="400px" persistent>
            <v-card>
                <v-card-title class="justify-center"
                    >你確定要刪除項目「{{ editedItem.name }}」嗎？</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDelete" :disabled="buttonLoading"
                        >取消</v-btn
                    >
                    <v-btn
                        color="error"
                        @click="deleteItemConfirm"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                        >刪除</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <template>
            <div class="text-center">
                <v-snackbar
                    v-model="snackBar.on"
                    :timeout="snackBar.timeout"
                    :color="snackBar.type"
                    right
                >
                    {{ snackBar.text }}
                </v-snackbar>
            </div>
        </template>
    </div>
</template>

<script>
import { menuConsoleServices } from "@/services/menuConsole.js";

export default {
    name: "MenuList",
    components: {},
    data() {
        return {
            mainFuncTitle: "內容列表",
            breadcrumbsItems: [],
            valid: true,
            refId: "",
            loading: true,
            page: -1,
            totalPage: -1,
            dialogDelete: false,

            headers: [
                {
                    text: "標題",
                    sortable: false,
                    value: "name",
                },
                { text: "建立日期", value: "createdAt" },
                { text: "修改日期", value: "updatedAt" },
                { text: "功能", value: "actions", sortable: false },
            ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                id: "",
                name: "",
                createdAt: "",
                updatedAt: "",
            },

            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            buttonLoading: false,
        };
    },
    computed: {
        funcTitle() {
            return this.mainFuncTitle;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    async created() {
        this.refId = this.$route.params.mid;
        let resData = await menuConsoleServices.getMenu(this.refId);
        let menuData = resData.data;
        if (menuData.contentType != "2" && menuData.contentType != "3") {
            this.$router.push("/404");
        }
        this.breadcrumbsItems.push({
            text: menuData.name,
            disabled: true,
            href: ``,
        });
        this.menuTitle = menuData.name;

        while (menuData.refId != null) {
            resData = await menuConsoleServices.getMenu(menuData.refId);
            menuData = resData.data;
            this.breadcrumbsItems.push({
                text: menuData.name,
                disabled: false,
                href: `/console/menu/${menuData.id}`,
            });
        }

        this.breadcrumbsItems.push({
            text: "前台選單列表",
            disabled: false,
            href: `/console/menu`,
        });

        this.breadcrumbsItems = this.breadcrumbsItems.reverse();
        this.page = 1;
        this.getPageData(1);
    },

    methods: {
        async getPageData(page) {
            let payload = {
                page: page,
            };

            const res = await menuConsoleServices.getMenuContentList(
                this.refId,
                payload
            );
            let data = res.data;
            this.totalPage = data.totalPage;
            this.loading = false;

            let contentList = data.list;
            this.desserts.length = 0;
            if (contentList.length != 0) {
                for (let content of contentList) {
                    this.desserts.push({
                        id: content.id,
                        name: content.title,
                        createdAt: content.createdAt,
                        updatedAt: content.updatedAt,
                    });
                }
            } else if (page == 1) {
                this.desserts.length = 0;
            } else {
                this.page = this.page - 1;
                if (page != 0) {
                    this.getPageData(this.page);
                }
            }
        },
        newItem() {
            this.$router.push(
                "/console/menu/:mid/content/:cid"
                    .replace(":mid", this.refId)
                    .replace(":cid", "")
            );
        },
        editItem(item) {
            this.$router.push(
                "/console/menu/:mid/content/:cid"
                    .replace(":mid", this.refId)
                    .replace(":cid", item.id)
            );
        },
        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.savingLock();
            await menuConsoleServices.deleteContent(this.editedItem.id);

            this.showAlertMsg("success", "刪除成功");
            this.savingUnLock();

            this.closeDelete();
            await this.getPageData(this.page);
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
    },
};
</script>

<style scoped>
</style>