<template>
    <v-app>
        <v-container>
            <v-row class="justify-center align-center" style="height: 85vh">
                <v-col class="col-12 col-sm-8 col-md-6 col-lg-4">
                    <h1 class="mb-2 text-center">管理平台</h1>
                    <v-alert type="error" outlined v-show="loginError">
                        帳號或密碼錯誤
                    </v-alert>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                            v-model="account"
                            label="帳號"
                            :rules="[accountRules.required]"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="password"
                            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPwd ? 'text' : 'password'"
                            label="密碼"
                            :rules="pwdRules"
                            @click:append="showPwd = !showPwd"
                            required
                        ></v-text-field>

                        <v-btn
                            :disabled="!valid"
                            color="success"
                            block
                            @click="login"
                            >登入
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import { loginServices } from "@/services/login.js";

export default {
    name: "Login",
    components: {},
    data() {
        return {
            loginError: false,
            valid: true,
            showPwd: false,
            accountRules: {
                required: (v) => !!v || "必須填寫帳號",
            },
            pwdRules: [(v) => !!v || "必須填寫密碼"],
            account: "",
            password: "",
        };
    },

    async created() {
        localStorage.removeItem('adminName')
    },
    methods: {
        async login() {
            this.loginError = false;
            if (this.$refs.form.validate()) {
                let data = {
                    account: this.account,
                    password: this.password,
                };
                const res = await loginServices.login(data);

                if (!res.success) {
                    this.loginError = true;
                } else {
                    const nameRes = await loginServices.getAdminName()
                    if (nameRes.data) {
                        localStorage.setItem('adminName', nameRes.data)
                    }
                    this.$router.push("/console");
                }
            }
            // this.$router.push("/scale");
        },
    },
};
</script>

<style scoped>
</style>