import servicesUtils from '../utils/service'

export const quizConsoleServices = {
    async getQuizResultList(payload) {
        try {

            const url = `${servicesUtils.resourceUrl('quizResultList')}` + '?' + servicesUtils.queryParams(payload)
            const res = await fetch(url, {
                method: 'get',
                responseType: 'blob',
                credentials: 'include'
            })
            return res
        } catch (err) {
            console.log(`failed to get getQuizResultList err: ${err}`)
            throw err
        }
    },

}