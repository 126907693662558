import servicesUtils from '../utils/service'

export const userServices = {
    // TODO
    // async fetchUsers(){    
    // },

    // async newUser(obj){
    // },

    async saveUserData(d) {
        try {
            const url = `${servicesUtils.resourceUrl('user')}/data`
            const data = await servicesUtils.post(url, d)
            return data
        } catch (err) {
            console.log(`failed to save user data: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },
}