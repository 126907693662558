<template>
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                    {{ item.title }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">
                {{ contentTitle != "" ? contentTitle : menuTitle }}
            </h2>
        </div>
        <div class="main-content row justify-center mt-4">
            <v-col class="col-12 col-sm-11 col-md-10 col-lg-8">
                <p class="text-right text--secondary">
                    發佈時間：{{ createdAt }}
                </p>
                <!--
                <v-img v-if="previewImg" :src="previewImg"/>
                -->
                <div v-html="content" class="ck-content"></div>
            </v-col>
        </div>
    </div>
</template>

<script>
import { menuServices } from "@/services/menu.js";

export default {
    name: "ContentTitleList",
    components: {},
    props: ["menuUrl", "menuTitle", "contentType", "contentId"],
    data() {
        return {
            content: "",
            previewImg: "",
            contentTitle: "",
            breadcrumbs: [],
            createdAt: "",
        };
    },
    async mounted() {
        if (this.contentId != -1) {
            const returnContent = await menuServices.getContent(this.contentId);
            let data = returnContent.data;
            if (!data.title) {
                this.$router.push("/404");
            }
            this.content = data.content;
            this.contentTitle = data.title;
            this.createdAt = data.createdAt;
            this.previewImg = data.previewImg;

            this.breadcrumbs.push(
                {
                    href: "/",
                    title: "首頁",
                    disabled: false,
                },
                {
                    href: "/" + this.menuUrl,
                    title: this.menuTitle,
                    disabled: false,
                },
                {
                    href: "",
                    title: data.title,
                    disabled: true,
                }
            );
        } else {
            const returnContent = await menuServices.getMenuContentList(
                this.menuUrl,
                {}
            );
            let data = returnContent.data;
            this.content = data.content;
            this.contentTitle = "";
            this.createdAt = data.createdAt;
            this.previewImg = data.previewImg
        }
    },
};
</script>

<style>
img {
    max-width: 100% !important;
    height: auto !important;
}
</style>