export const mutations = {
  setBearerToken(state, payload) {
    state.bearerToken = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  setConsoleIndexUrl(state, payload) {
    state.consoleIndexUrl = payload
  },
  setConsoleFuncList(state, payload) {
    state.consoleFuncList = payload
  },

  setQuizTitle(state, payload) {
    state.quizTitle = payload
  },
}