<template>
    <div class="pb-10">
        <div>
            <v-btn
                class="mr-4"
                min-width="0"
                outlined
                text
                small
                @click="$router.go(-1)"
                :title="回上一頁"
                :value="回上一頁"
                :aria-label="回上一頁"
            >
                <v-icon>mdi-arrow-left</v-icon>回上一頁
            </v-btn>
        </div>
         <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">資料確認</h2>
        </div>
        <div class="main-content py-3 px-10">
            <form ref="form">
                <div>
                    <small>生理性別</small>
                    <v-radio-group
                        ref="gender"
                        :error-messages="requiredErrors('gender')"
                        v-model.trim="$v.gender.$model"
                        @input="$v.gender.$touch()"
                        @blur="$v.gender.$touch()"
                        row
                    >
                        <v-radio
                            label="男"
                            value="MALE"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="女"
                            value="FEMALE"
                            color="#82bf5a"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div>
                    <small>出生年月日</small>
                    <v-menu
                        v-model="birthDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                ref="birthDate"
                                v-model.trim="$v.birthDate.$model"
                                :error-messages="
                                    requiredErrors('birthDate')
                                "
                                @input="$v.birthDate.$touch()"
                                @blur="$v.birthDate.$touch()"
                                label="年-月-日"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="birthDate"
                            @input="birthDateMenu = false"
                            locale="zhHant"
                            :max="nowDate"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <small>目前居住情況</small>
                    <v-radio-group
                        ref="liveStatus"
                        v-model.trim="$v.liveStatus.$model"
                        :error-messages="requiredErrors('liveStatus')"
                        @input="$v.liveStatus.$touch()"
                        @blur="$v.liveStatus.$touch()"
                        row
                    >
                        <v-radio
                            label="獨居"
                            value="ALONE"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="與親密伴侶、伴侶或家人同"
                            value="FAMILY"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="與同事/朋友/室友同住"
                            value="ROOM_MATE"
                            color="#82bf5a"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div>
                    <small>婚姻狀況</small>
                    <v-radio-group
                        ref="maritalStatus"
                        v-model.trim="$v.maritalStatus.$model"
                        :error-messages="requiredErrors('maritalStatus')"
                        @input="$v.maritalStatus.$touch()"
                        @blur="$v.maritalStatus.$touch()"
                        row
                    >
                        <v-radio
                            label="已婚"
                            value="MARRIED"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="未婚"
                            value="SINGLE"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="離婚"
                            value="DIVORCED"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="喪偶"
                            value="WIDOWED"
                            color="#82bf5a"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div>
                    <small>最高學歷</small>
                    <v-select
                        ref="education"
                        v-model.trim="$v.edu.$model"
                        :error-messages="requiredErrors('edu')"
                        @input="$v.edu.$touch()"
                        @blur="$v.edu.$touch()"
                        :items="education"
                        label="選擇最高學歷"
                        item-text="item"
                        item-value="id"
                        outlined
                        solo
                    ></v-select>
                </div>
                <div>
                    <small>居住地</small>
                    <v-select
                        ref="city"
                        v-model.trim="$v.city.$model"
                        :error-messages="requiredErrors('city')"
                        @input="$v.city.$touch()"
                        @blur="$v.city.$touch()"
                        :items="twCities"
                        item-text="item"
                        item-value="code"
                        label="選擇居住地"
                        outlined
                        solo
                    ></v-select>
                </div>

                <div v-show="isShowPlaceField">
                    <small>海外居住地</small>
                    <v-text-field
                        ref="placeName"
                        v-model="placeName"
                        label="輸入居住地"
                        :error-messages="requiredErrors('placeName')"
                        outlined
                        solo
                        hide-details="auto"
                    ></v-text-field>
                </div>
                <div>
                    <v-checkbox
                        ref="policy_hasSpirit"
                        v-model="hasSpirit"
                        label="我已在生活中操練精神健康之道"
                    ></v-checkbox>
                    <v-checkbox
                        ref="policy_hasSubscribed"
                        v-model="hasSubscribed"
                        label="我已是生活健康家族或已訂閱本會會刊"
                    ></v-checkbox>
                    <v-checkbox
                        ref="memberPrivacy"
                        v-model.trim="$v.memberPrivacy.$model"
                        :error-messages="requiredErrors('memberPrivacy')"
                        @input="$v.memberPrivacy.$touch()"
                        @blur="$v.memberPrivacy.$touch()"
                    >
                        <template v-slot:label>
                            <div>
                                我同意
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            @click="termDialog = true"
                                            v-on="on"
                                        >
                                            會員條款與隱私權
                                        </a>
                                    </template>
                                    會員條款與隱私權
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <div>
                    <v-btn
                        class="w-100"
                        color="#82bf5a"
                        @click="goToQuiz()"
                        dark
                         title="送出" value="送出" aria-label="送出"
                    >
                        送出
                    </v-btn>
                </div>
            </form>
        </div>
        
        <v-dialog v-model="termDialog" persistent max-width="350">
            <v-card class="pb-2">
                <v-card-text class="body-1 pt-10">
                    為保護您的權益，請詳細閱讀以下隱私權政策。當您開始進行測驗，即代表您同意本服務的隱私權政策。<br />
                    1. 資料蒐集與運用<br />
                    當您使用本服務進行檢測時，本會將要求您（使用者）提供性別、出生年月日、工作情況、婚姻狀況、居住狀況、最高學歷、居住縣市等、測驗填答內容與結果，這些資料將可能作為本會非營利性質的學術研究或教育推廣使用。<br />
                    2. 個人資料安全<br />
                    a.
                    本會依相關法律規定，採取適當的安全措施，以保存您所提供的個人資料，並且僅限於因業務需求相關的本會人員，始有權限接觸到您的個人資料。<br />
                    b.
                    所有資料僅供本會為了提供服務與非營利學術研究或推廣使用，除非事先經過您的同意或依相關法令規定，不會將個人資料提供給第三方或轉作其他目的使用。
                </v-card-text>

                <v-card-actions class="mt-5">
                    <v-btn
                        outlined
                        @click="termDialog = false"
                        block
                    >
                        確定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { userServices } from "@/services/user.js";
import { cityServices } from "@/services/city.js";
import { educationServices } from "@/services/education.js";
import { quizServices } from "@/services/quiz.js";

export default {
    name: "PersonalData",
    components: {},
    data() {
        return {
            quizId: -1,
            city: "",
            placeName: "",
            edu: "",
            birthDateMenu: false,
            birthDate: "",
            gender: "",
            liveStatus: "",
            maritalStatus: "",
            memberPrivacy: false,
            hasSpirit: false,
            hasSubscribed: false,
            education: [],
            twCities: [],
            nowDate: new Date().toISOString().slice(0,10),
            termDialog: false,
            isShowPlaceField: false,
        };
    },
    async created() {
        
        let id = this.$route.params.id;
        const res = await quizServices.getQuizInstructions(id);
        const quiz = res.data;
        if (!quiz.id) {
            this.$router.push("/scale")
        }
        if (quiz.id != sessionStorage.quiz_id) {
            this.$router.push("/scale/:id/instruction".replace(':id', quiz.id))
        }
        this.quizId = quiz.id;

        this.$store.commit("setQuizTitle", quiz.title);
        //this.$root.$emit('scaleTitle', quiz.title);

        sessionStorage.quiz_id = quiz.id;

        const edu = await educationServices.getEducation();
        this.education = edu.data;
        const city = await cityServices.getTWCities();
        this.twCities = city.data;

        const saveUserData = localStorage.getItem('userData');
        if (saveUserData != null) {
            const userData =  JSON.parse(saveUserData)
            this.birthDate = userData.birthDate
            this.gender = userData.gender
            this.edu = userData.educationId
            this.city = userData.twCitiesCode
            this.placeName = userData.placeName
            this.liveStatus = userData.liveStatus
            this.maritalStatus = userData.maritalStatus
            this.hasSubscribed = userData.policy.hasSubscribed
            this.hasSpirit = userData.policy.hasSpirit
        }
        
    },
    validations: {
        city: { required },
        edu: { required },
        birthDate: { required },
        gender: { required },
        liveStatus: { required },
        maritalStatus: { required },
        memberPrivacy: { required, sameAs: (val) => val === true },
        placeName: { required },
    },
    watch: {
        city(val) {
            if (val == 'OS') {
                this.isShowPlaceField = true;
                if (this.placeName == '無')
                    this.placeName = '';
            } else {
                this.isShowPlaceField = false;
                this.placeName = '無';
            }
        },
    },
    methods: {
        requiredErrors(input) {
            const errors = [];
            
            if (!this.$v[input].$dirty) return errors;
            if (!this.$v[input].required) {
                switch (input) {
                    case "edu":
                        errors.push("請選擇最高學歷");
                        break;
                    case "city":
                        errors.push("請選擇居住地");
                        break;
                    case "gender":
                        errors.push("請選擇性別");
                        break;
                    case "birthDate":
                        errors.push("請填寫生日");
                        break;
                    case "liveStatus":
                        errors.push("請選擇居住狀況");
                        break;
                    case "maritalStatus":
                        errors.push("請選擇婚姻狀況");
                        break;
                    case "placeName":
                        errors.push("請輸入居住地");
                        break;
                }
            } else {
                if (input === "memberPrivacy" && !this.$v[input].sameAs) {
                    errors.push("請同意會員條款與隱私權");
                }
            }

            return errors;
        },
        async goToQuiz() {
            // await this.saveUserData();
            this.$v.$reset();
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let id = await this.saveUserData()
                sessionStorage.user_id = id;
                this.$router.push("/scale/:id/quest".replace(':id', this.$route.params.id))
            } 
            // this.$router.push("/scale");
        },
        async saveUserData() {
            let data = {
                birthDate: this.birthDate,
                gender: this.gender,
                educationId: this.edu,
                twCitiesCode: this.city,
                placeName: this.placeName,
                liveStatus: this.liveStatus,
                maritalStatus: this.maritalStatus,
                applyMemberPrivacy: this.memberPrivacy,
                policy: {
                    hasSubscribed: this.hasSubscribed,
                    hasSpirit: this.hasSpirit,
                },
            };
            localStorage.setItem('userData', JSON.stringify(data))
            let user = await userServices.saveUserData(data);
            return user.data;
        },
    },
};
</script>

<style scoped>
.title {
    width: 300px;
    padding-top: 30px;
    margin-top: 30px;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.title span {
    padding-bottom: 10px;
    border-bottom: 2px solid #20780c;
    line-height: 48px;
}

.v-input--selection-controls {
    margin-top: 2px;
    padding-top: 4px;
    margin-bottom: 2px;
    padding-bottom: 4px;
}

.v-text-field {
    padding-top: 12px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-bottom: 4px;
}

.v-input__slot {
    margin-top: 6px;
    margin-bottom: 6px;
}
.main-content {
    max-width: 650px;
}
</style>