<template>
    <div id="apa" class="mx-auto" style="max-width: 400px">
        <h2 border="bottom" class="mb-2 text-center">{{ funcTitle }}</h2>
        <v-alert type="error" outlined v-show="updateError">
            舊密碼錯誤
        </v-alert>
        <v-card outlined class="py-3 px-5">
            <v-form ref="pwdForm" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="oldPwd"
                            :append-icon="
                                showOldPwd ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showOldPwd ? 'text' : 'password'"
                            label="舊密碼"
                            :rules="oldPwdRules"
                            @click:append="showOldPwd = !showOldPwd"
                            required
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="newPwd"
                            :append-icon="
                                showNewPwd ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showNewPwd ? 'text' : 'password'"
                            label="新密碼"
                            :rules="newPwdRules"
                            @click:append="showNewPwd = !showNewPwd"
                            required
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="reNewPwd"
                            :append-icon="
                                showReNewPwd ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showReNewPwd ? 'text' : 'password'"
                            label="再輸入一次新密碼"
                            :rules="reNewpwdRules"
                            @click:append="showReNewPwd = !showReNewPwd"
                            required
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn text :disabled="buttonLoading" to="/">
                            取消
                        </v-btn>
                        <v-btn
                            color="light-green darken-2 white--text"
                            @click="saveContent"
                            :loading="buttonLoading"
                            :disabled="buttonLoading"
                        >
                            儲存
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <template>
            <div class="text-center">
                <v-snackbar
                    v-model="snackBar.on"
                    :timeout="snackBar.timeout"
                    :color="snackBar.type"
                    right
                >
                    {{ snackBar.text }}
                </v-snackbar>
            </div>
        </template>
    </div>
</template>

<script>
import { loginServices } from "@/services/login.js";

export default {
    name: "MenuList",
    components: {},
    data() {
        return {
            breadcrumbsItems: [],
            funcTitle: "我的帳號",
            oldPwd: "",
            newPwd: "",
            reNewPwd: "",
            oldPwdErrorMsg: "",
            newPwdErrorMsg: "",
            reNewPwdErrorMsg: "",
            oldPwdRules: [(v) => !!v || "必須填寫舊密碼"],
            newPwdRules: [
                (v) => !!v || "必須填寫新密碼",
                (v) => !((!/[a-z]+/.test(v) && !/[A-Z]+/.test(v) ) || !/\d+/.test(v) || v.length < 8) || "密碼必須大於8碼且包含英文字母及數字",
            ],
            reNewpwdRules: [
                (v) => !!v || "必須再填寫新密碼",
                (v) => (v == this.newPwd) || "與新密碼不同"
            ],
            showOldPwd: false,
            showNewPwd: false,
            showReNewPwd: false,
            valid: true,
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            buttonLoading: false,
            updateError: false,
        };
    },
    computed: {},
    watch: {},

    async created() {
        this.breadcrumbsItems.push({
            text: "我的帳號",
            disabled: true,
            href: ``,
        });
    },
    methods: {
        async saveContent() {
            this.updateError = false;
            if (this.$refs.pwdForm.validate()) {
                this.savingLock();
                // await menuConsoleServices.saveOrUpdateContent(
                //     this.refId,
                //     this.contentId == -1 ? "" : this.contentId,
                //     {
                //         content: this.editorData,
                //         plain_text: this.plainText,
                //         title: this.title,
                //         preview_img: this.previewImgFileUrl,
                //     }
                // );
                let res = await loginServices.updatePwd(this.oldPwd, this.newPwd);
                if (res.data.error_id) {
                    this.updateError = true;
                } else {
                    this.showAlertMsg("success", "更新密碼成功");
                    this.$refs.pwdForm.reset();
                }
                
                
                this.savingUnLock();
            }
        },

        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
    },
};
</script>

<style scoped>
</style>