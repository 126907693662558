<template>
    <div id="apa">
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large
        ></v-breadcrumbs>

        <h2 border="bottom" class="mb-2">{{ mainFuncTitle }}</h2>
        <v-card outlined class="py-3 px-5">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <v-select
                        :items="assessments"
                        label="選擇量表"
                        item-text="title"
                        item-value="id"
                        v-model="selectAssessment"
                        :error-messages="assessmentErrorMsg"
                        :error="assessmentError"
                        required
                        return-object
                        hide-details="auto"
                    ></v-select>
                </div>
                <div style="width: 100%"></div>
                <div class="col-12 col-sm-6">
                    <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startDate"
                                label="選擇開始時間"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startDate"
                            no-title
                            scrollable
                            locale="zhHant"
                            :max="startMaxDate"
                        >
                        </v-date-picker>
                    </v-menu>
                </div>

                <div class="col-12 col-sm-6">
                    <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endDate"
                                label="選擇結束時間"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            no-title
                            scrollable
                            locale="zhHant"
                            :min="endMinDate"
                        >
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="col-12 text-right">
                    <v-btn
                        color="light-green darken-2 white--text"
                        @click="downloadExcel"
                        :loading="buttonLoading"
                        :disabled="buttonLoading"
                    >
                        下載量表
                    </v-btn>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import fileDownload from "js-file-download";
import { quizConsoleServices } from "@/services/quizConsole.js";
import { quizServices } from "@/services/quiz.js";

export default {
    name: "AssessmentList",
    components: {},
    data() {
        return {
            breadcrumbsItems: [],
            mainFuncTitle: "下載量表資料",
            assessments: [],
            startDateMenu: false,
            endDateMenu: false,
            startMaxDate: "",
            startDate: "",
            endMinDate: "",
            endDate: "",
            selectAssessment: Object,
            assessmentError: false,
            assessmentErrorMsg: "",
            buttonLoading: false,
        };
    },
    async created() {
        this.breadcrumbsItems.push({
            text: this.mainFuncTitle,
            disabled: true,
            href: ``,
        });
        const quiz = await quizServices.getQuizList();
        this.assessments = quiz.data;
    },
    watch: {
        startDate: function () {
            this.endMinDate = this.startDate;
        },
        endDate: function () {
            this.startMaxDate = this.endDate;
        },
        selectAssessment: function () {
            this.assessmentError = false;
            this.assessmentErrorMsg = "";
        },
    },
    methods: {
        async downloadExcel() {
            if (typeof this.selectAssessment.id == "undefined") {
                this.assessmentError = true;
                this.assessmentErrorMsg = "請選擇量表";
            } else {
                this.savingLock();
                const selectAssessmentTitle = this.selectAssessment.title;
                const res = await quizConsoleServices.getQuizResultList({
                    id: this.selectAssessment.id,
                    start_date: this.startDate,
                    end_date: this.endDate,
                });
                if (res) {
                    res.blob().then(function (myBlob) {
                        fileDownload(myBlob, selectAssessmentTitle + ".xlsx");
                    });
                    this.savingUnLock();
                }
            }
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
    },
};
</script>

<style scoped>
</style>