import servicesUtils from '../utils/service'

export const menuConsoleServices = {
    async getMenu(mid) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenu')}`.replace('${id}', mid)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getMenu err: ${err}`)
            throw err
        }
    },

    async getMenuList(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenuList')}` + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get consoleMenuList err: ${err}`)
            throw err
        }
    },
    async saveOrUpdateMenu(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenu')}`.replace('${id}', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to saveOrUpdateMenu err: ${err}`)
            throw err
        }
    },
    async deleteMenu(id) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenu')}`.replace('${id}', id)
            const data = await servicesUtils.delete(url)
            return data
        } catch (err) {
            console.log(`failed to deleteMenu err: ${err}`)
            throw err
        }
    },

    async saveOrUpdateMenuLink(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('ConsoleSaveMenuLink')}`.replace('${id}', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to saveOrUpdateMenuLink err: ${err}`)
            throw err
        }
    },

    async getMenuContentList(mid, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenuContentList')}`.replace('${mid}', mid) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getMenuContentList err: ${err}`)
            throw err
        }
    },

    async getContent(contentId) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleContent')}`.replace('${id}', contentId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getContent err: ${err}`)
            throw err
        }
    },

    async saveOrUpdateContent(mid, cid, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleMenuContent')}`.replace('${mid}', mid).replace('${cid}', cid)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to saveOrUpdateContent err: ${err}`)
            throw err
        }
    },
    async deleteContent(id) {
        try {
            const url = `${servicesUtils.resourceUrl('consoleContent')}`.replace('${id}', id)
            const data = await servicesUtils.delete(url)
            return data
        } catch (err) {
            console.log(`failed to deleteContent err: ${err}`)
            throw err
        }
    },
    
    async uploadFile(file) {
        try {
            let formData = new FormData();
			formData.append("file", file);	
            const url = `${servicesUtils.resourceUrl('consoleFile')}`
            const data = await servicesUtils.postFormData(url, formData)
            return data
        } catch (err) {
            console.log(`failed to uploadFile err: ${err}`)
            throw err
        }
    },
}