import servicesUtils from '../utils/service'

export const cityServices = {
    async getTWCities(d) {
        try {
            const url = `${servicesUtils.resourceUrl('city')}/tw`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get tw cities: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },
}