<template>
    <v-app>
        <AppHeader />
        <div :class="mainDivClass">
        <v-container>
            <router-view :key="$route.path"></router-view>
        </v-container>
        </div>
        <AppFooter />
    </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
    name: "AppPortal",

    components: {
        AppHeader,
        AppFooter,
    },

    data: () => ({
        //
    }),
    computed: {
        mainDivClass: {
            get() {
                if (!this.$store.state.quizTitle) {
                    return "main-section";
                } else {
                    return "grey lighten-5";
                }
            },
        },
    },
};
</script>

<style>
#app {
    max-width: 100%;
    /* margin: 0 auto;
    padding: 2rem; */

    font-weight: normal;
    background-color: var(--v-background-lighten5); /*--v-background-base*/
}
.page-title {
    border-bottom: 2px solid #20780c;
}
.main-section {
    background-image: url(~@/assets/img/main_bg.png);
    background-size: contain;
    background-position: bottom;
    padding-bottom: 50px;
}
.main-content {
    min-height: calc(100vh - 338px);
    margin-left: auto !important;
    margin-right: auto !important;
}
.main-content.error-page {
    min-height: 58vh;
    margin-left: auto !important;
    margin-right: auto !important;
}
.w-100 {
    width: 100%;
}
/* @media (min-width: 1024px) {
    body {
        display: flex;
        place-items: center;
    }

    #app {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 2rem;
    }

    .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        margin-left: -1rem;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
} */
</style>