<template>
    <div>
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">評量選擇</h2>
        </div>
        <div class="main-content row no-gutters justify-center mt-4" style="max-width: 600px;">
            <v-col v-for="(q, qIndex) in quizList" :key="qIndex" cols="12" class="mb-3">
                <v-card>
                    <div class="d-flex flex-no-wrap">

                        <v-avatar class="ma-3" size="150" tile>
                            <v-img :src="q.thumbnailUrl" :alt="q.title"></v-img>
                        </v-avatar>
                        <div>
                            <v-card-title class="text-h6" v-text="q.title"></v-card-title>

                            <v-card-subtitle v-text="q.description"></v-card-subtitle>

                            <v-card-actions>
                            <v-btn class="ml-2" outlined rounded small justify="end" :title="`前往${q.title}`" :value="`前往${q.title}`" :aria-label="`前往${q.title}`" @click="goToQuizInstruction(q.id)">
                                查看量表
                            </v-btn>
                            </v-card-actions>
                        </div>

                    </div>
                </v-card>
            </v-col>
        </div>
    </div>
</template>

<script>
import { quizServices } from "@/services/quiz.js";

export default {
    name: "QuizList",
    components: {},
    data() {
        return {
            quizList: [],
        };
    },
    async created() {
        window.scrollTo(0, 0);
        const quiz = await quizServices.getQuizList();
        this.quizList = quiz.data;
        
        this.$store.commit("setQuizTitle", '');
        //this.$root.$emit('scaleTitle', '');
        sessionStorage.removeItem('quiz_id');
        sessionStorage.removeItem('user_id');
    },
    methods: {
        goToQuizInstruction(id) {
            this.$router.push('/scale/${id}/instruction'.replace('${id}', id));
        },
    },
};
</script>

<style scoped>

</style>