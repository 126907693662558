<template>
    <div id="apa">
        <v-breadcrumbs
            :items="breadcrumbsItems"
            class="pa-0 mb-2"
            large
        ></v-breadcrumbs>
        <h2 border="bottom" class="mb-2">{{ funcTitle }}</h2>
        <v-card outlined class="py-3 px-5">
            <v-form ref="contentForm" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="title"
                            label="標題"
                            hide-details="auto"
                            :rules="titleRules"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="contentType == '3'">
                        <v-file-input
                            accept="image/*"
                            label="縮圖檔案"
                            @change="uploadPreviewImg"
                            ref="previewFile"
                            :rules="previewImgRules"
                            :loading="isLoadingPreviewFile"
                        ></v-file-input>
                        <template v-if="previewImgFileUrl != ''">
                            縮圖預覽：<v-img
                                max-height="150"
                                max-width="250"
                                :src="previewImgFileUrl"
                            ></v-img>
                        </template>
                    </v-col>
                    <v-col cols="12">
                        <v-sheet color="white" elevation="1">
                            <ckeditor
                                :editor="editor"
                                v-model="editorData"
                                :config="editorConfig"
                                @ready="onReady"
                            >
                                <div class="document-editor">
                                    <div class="document-editor__toolbar"></div>
                                    <div
                                        class="
                                            document-editor__editable-container
                                        "
                                    >
                                        <div class="document-editor__editable">
                                            <p>The initial editor data.</p>
                                        </div>
                                    </div>
                                </div>
                            </ckeditor>
                            <!--
                            <v-file-input
                                v-model="plainText"
                                :rules="contentRules"
                            ></v-file-input>
                            -->
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            :disabled="buttonLoading"
                            @click="goMenuContents"
                        >
                            取消
                        </v-btn>
                        <v-btn
                            color="light-green darken-2 white--text"
                            @click="saveContent"
                            :loading="buttonLoading"
                            :disabled="buttonLoading"
                        >
                            儲存
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <template>
            <div class="text-center">
                <v-snackbar
                    v-model="snackBar.on"
                    :timeout="snackBar.timeout"
                    :color="snackBar.type"
                    right
                >
                    {{ snackBar.text }}
                </v-snackbar>
            </div>
        </template>
    </div>
</template>

<script>
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-classic/build/translations/zh";
import UploadAdapter from "@/services/uploadAdapter.js";
//import fileUploadServices from "@/services/fileUpload.js";
import { fileUploadServices } from "@/services/fileUpload.js";
import { menuConsoleServices } from "@/services/menuConsole.js";

export default {
    name: "MenuList",
    components: {},
    data() {
        return {
            breadcrumbsItems: [],
            funcTitle: "",
            refId: "",
            menuTitle: "",
            contentType: "",
            contentId: -1,
            contentTitle: "",
            title: "",
            previewImgFile: null,
            previewImgFileUrl: "",
            isLoadingPreviewFile: false,
            valid: false,
            titleRules: [(v) => !!v || "必須填寫標題"],
            previewImgRules: [
                (v) => !!v || !!this.previewImgFileUrl || "必須選擇縮圖",
            ],
            contentRules: [() => !!this.plainText || "必須填寫內容"],
            editor: DecoupledEditor,
            plainText: "",
            editorData: "",
            editorConfig: {
                // Run the editor with the German UI.
                language: "zh",
                extraPlugins: [this.uploader],
                toolbar: {
                    items: [
                        /*"heading",
                        "|",*/
                        "undo",
                        "redo",
                        "|",
                        "fontSize",
                        "fontColor",
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "horizontalLine",
                        "|",
                        "alignment",
                        "outdent",
                        "indent",
                        "|",
                        "imageUpload",
                        "blockQuote",
                        "mediaEmbed",
                    ],
                },
                mediaEmbed: {
                    previewsInData: true,
                },
                image: {
                    toolbar: [
                        "imageTextAlternative",
                        "imageStyle:inline",
                        "imageStyle:block",
                        "imageStyle:side",
                    ],
                },

                fontColor: {
                    colors: [
                        {
                            color: "hsl(0, 0%, 0%)",
                            label: "Black",
                        },
                        {
                            color: "hsl(0, 0%, 30%)",
                            label: "Dim grey",
                        },
                        {
                            color: "hsl(0, 0%, 60%)",
                            label: "Grey",
                        },
                        {
                            color: "hsl(0, 0%, 90%)",
                            label: "Light grey",
                        },
                        {
                            color: "hsl(0, 0%, 100%)",
                            label: "White",
                            hasBorder: true,
                        },
                        {
                            color: "hsl(0, 75%, 60%)",
                            label: "Red",
                        },
                        {
                            color: "hsl(30, 75%, 60%)",
                            label: "Orange",
                        },
                        {
                            color: "hsl(60, 75%, 60%)",
                            label: "Yellow",
                        },
                        {
                            color: "hsl(90, 75%, 60%)",
                            label: "Light green",
                        },
                        
                        {
                            color: "#d1d389",
                            label: "橄欖綠",
                        },
                        {
                            color: "hsl(120, 75%, 60%)",
                            label: "Green",
                        },
                        {
                            color: "#008000",
                            label: "深綠",
                        },
                        {
                            color: "hsl(150, 75%, 60%)",
                            label: "Aquamarine",
                        },
                        {
                            color: "hsl(180, 75%, 60%)",
                            label: "Turquoise",
                        },
                        {
                            color: "hsl(210, 75%, 60%)",
                            label: "Light blue",
                        },
                        {
                            color: "hsl(240, 75%, 60%)",
                            label: "Blue",
                        },
                        {
                            color: "hsl(270, 75%, 60%)",
                            label: "Purple",
                        },
                        {
                            color: "#ff7a6e",
                            label: "桃粉紅",
                        },
                        {
                            color: "#b68476",
                            label: "淺咖啡",
                        },
                    ],
                },
            },
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            buttonLoading: false,
        };
    },
    computed: {},
    watch: {},

    async created() {
        let menuId = this.$route.params.mid;
        let contentId = this.$route.params.cid;

        let resData = await menuConsoleServices.getMenu(menuId);
        let menuData = resData.data;
        if (
            menuData.contentType != "2" &&
            menuData.contentType != "3" &&
            menuData.contentType != "4"
        ) {
            this.$router.push("/404");
        }
        this.menuTitle = menuData.name;
        this.contentType = menuData.contentType;

        this.refId = menuId;
        if (typeof contentId != "undefined") {
            const res = await menuConsoleServices.getContent(contentId);
            const contentData = res.data;
            this.editorData = contentData.content;
            this.title = contentData.title;
            this.previewImgFileUrl = contentData.previewImg;
            this.contentId = contentId;
        } else {
            this.contentId = -1;
        }
        this.funcTitle = this.contentId === -1 ? "新增內容" : "修改內容";

        if (menuData.contentType == "4") {
            this.breadcrumbsItems.push({
                text: menuData.name,
                disabled: true,
                href: ``,
            });
        } else {
            this.breadcrumbsItems.push(
                {
                    text: this.funcTitle,
                    disabled: true,
                    href: ``,
                },
                {
                    text: menuData.name,
                    disabled: false,
                    href: `/console/menu/${menuData.id}/contents`,
                }
            );
        }

        while (menuData.refId != null) {
            resData = await menuConsoleServices.getMenu(menuData.refId);
            menuData = resData.data;
            this.breadcrumbsItems.push({
                text: menuData.name,
                disabled: false,
                href: `/console/menu/${menuData.id}`,
            });
        }

        this.breadcrumbsItems.push({
            text: "前台選單列表",
            disabled: false,
            href: `/console/menu`,
        });

        this.breadcrumbsItems = this.breadcrumbsItems.reverse();
    },
    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
        },
        async saveContent() {
            if (this.editorData) {
                let dom = document.createElement("DIV");
                dom.innerHTML = this.editorData;
                this.plainText = dom.textContent || dom.innerText;
                dom.remove();
            }

            if (this.$refs.contentForm.validate()) {
                this.savingLock();
                await menuConsoleServices.saveOrUpdateContent(
                    this.refId,
                    this.contentId == -1 ? "" : this.contentId,
                    {
                        content: this.editorData,
                        plain_text: this.plainText,
                        title: this.title,
                        preview_img: this.previewImgFileUrl,
                    }
                );

                this.showAlertMsg("success", "儲存成功");
                //讓他睡一下
                await new Promise(resolve => setTimeout(resolve, this.snackBar.timeout));

                this.goMenuContents();
                
            }
        },
        goMenuContents() {
            if (this.contentType == "4") {
                this.$router.push("/console/menu");
            } else {
                this.$router.push(
                    "/console/menu/:mid/contents".replace(":mid", this.refId)
                );
            }
        },
        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        savingLock() {
            this.buttonLoading = true;
        },
        savingUnLock() {
            this.buttonLoading = false;
        },
        async uploadPreviewImg(file) {
            if (file) {
                this.isLoadingPreviewFile = true;
                let res = await fileUploadServices.uploadFile(file);
                this.previewImgFileUrl = res.url;
                this.isLoadingPreviewFile = false;
            }
        },
        uploader(editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = (
                loader
            ) => {
                return new UploadAdapter(loader);
            };
        },
    },
};
</script>

<style scoped>
.ck-editor__editable {
    max-height: 400px;
}
</style>