import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index'
import QuizInstructions from '@/components/QuizInstructions'
import PersonalData from '@/components/PersonalData'
import Quiz from '@/components/Quiz'
import QuizResult from '@/components/QuizResult'
import QuizList from '@/components/QuizList'
import Content from '@/components/Content'

import consoleAsessmentList from '@/components/console/AssessmentList'
import consoleMenuList from '@/components/console/MenuList'
import consoleContentList from '@/components/console/ContentList'
import consoleContentEdit from '@/components/console/ContentEdit'
import consoleIndex from '@/components/console/Index'
import consoleMy from '@/components/console/My'
import consoleAdminUserList from '@/components/console/AdminUserList'

import Login from '@/components/console/Login'

import NotFound from '@/components/NotFound'
import ErrorPage from '@/components/ErrorPage'
//import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/error',
        component: ErrorPage,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/scale/:id/instruction',
        name: 'QuizInstructions',
        component: QuizInstructions,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/scale/:id/personalData',
        name: 'PersonalData',
        component: PersonalData,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/scale',
        name: 'QuizList',
        component: QuizList,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/scale/:id/quest',
        name: 'Quiz',
        component: Quiz,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/scale/result/:uuid',
        name: 'QuizResult',
        component: QuizResult,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/console/',
        name: 'consoleIndex',
        component: consoleIndex,
        meta: {
            layout: 'console',
        }
    },
    {
        path: '/console/my',
        name: 'consoleMy',
        component: consoleMy,
        meta: {
            layout: 'console',
        }
    },
    {
        path: '/console/assessments',
        name: 'consoleAsessmentList',
        component: consoleAsessmentList,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },
    {
        path: '/console/menu',
        name: 'consoleMenuList',
        component: consoleMenuList,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },

    {
        path: '/console/menu/:mid',
        name: 'consoleSubMenuList',
        component: consoleMenuList,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },

    {
        path: '/console/menu/:mid/contents',
        name: 'consoleContentList',
        component: consoleContentList,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },

    {
        path: '/console/menu/:mid/content',
        name: 'consoleContentAdd',
        component: consoleContentEdit,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },
    {
        path: '/console/menu/:mid/content/:cid',
        name: 'consoleContentEdit',
        component: consoleContentEdit,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },
    {
        path: '/console/adminUser',
        name: 'consoleAdminUserList',
        component: consoleAdminUserList,
        meta: {
            layout: 'console',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            layout: 'default'
        }
    },
    {
        path: '/:menuUrl',
        name: 'Content',
        component: Content,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '/:menuUrl/:contentId',
        name: 'HtmlContent',
        component: Content,
        meta: {
            layout: 'portal'
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: NotFound,
        meta: {
            layout: 'portal'
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
    //console.log('to=', to.fullPath, '| from=', from.fullPath);
    if (to.matched.some(record => {
        //console.log(record.name, record.meta.requiresAuth);
        return record.meta.requiresAuth;
    })) {
        // 如果沒有 token 
        let flag = localStorage.getItem('adminName');
        //console.log('adminName?', sessionStorage.getItem('adminName'));
        if (!flag) {
            // 轉跳到 login page
            next({ path: '/login' });
        } else {
            //要判斷是否能進去
            let isAllow = true;

            const funcListInSession = localStorage.getItem("consoleFuncList");
            //('funcListInSession:',funcListInSession)
            if (funcListInSession) {
                isAllow = false;
                const consoleFuncList = JSON.parse(funcListInSession);
                for (let funcUrl of consoleFuncList) {
                    //console.log(to.fullPath + ' ' + funcUrl)
                    if (to.fullPath.indexOf(funcUrl) != -1) {
                        isAllow = true;
                        break;
                    }
                }
            }
            if (isAllow) {
                next(); //成功可以進去
            } else {
                next({ path: '/404' })
            }
        }
    } else {
        next(); // 往下繼續執行
    }
});
export default router;