import servicesUtils from '../utils/service'

export const fileUploadServices = {
    async uploadFile(file) {
        try {
            let formData = new FormData();
			formData.append("file", file);	
            const url = `${servicesUtils.resourceUrl('consoleFile')}`
            const data = await servicesUtils.postFormData(url, formData)
            return data
        } catch (err) {
            console.log(`failed to uploadFile err: ${err}`)
            throw err
        }
    },
}