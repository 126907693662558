import { loginServices } from '@/services/login'

export const actions = {
    // TODO
    async userSignIn({ commit }, payload) {
        const res = await loginServices.login(payload)
        
        if (res.token) {
            commit('setBearerToken', res.token)
        }
    },

    consoleIndexUrl({ commit }, payload) {
        commit('setConsoleIndexUrl', payload)
    },
    consoleFuncList({ commit }, payload) {
        commit('setConsoleFuncList', payload)
    },

    quizTitle({ commit }, payload) {
        commit('setQuizTitle', payload)
    },
    // async userSignOut ({commit}) {

    // }
}