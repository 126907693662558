<template>
    <v-footer class="py-5 grey lighten-3">
        
        <div class=" text-center pa-5" style="width: 100%;">
            
            <h2 class="mb-2">更多相關資源</h2>
            <p>
                提醒您，多加操練精神健康之道有益健康喔！
            </p>
            <div>
                <v-btn class="rounded-pill mx-2 mb-2" large outlined color="#555" width="200px" to="/">
                   了解健康精神之道
                </v-btn>
                <v-btn class="rounded-pill mx-2 mb-2" large outlined color="#555" width="200px" to="/contact">
                   聯絡我們
                </v-btn>
            </div>
        </div>
    </v-footer>
</template>

<script>
export default {
    name: "AppFooter",
    components: {},
};
</script>

<style scoped>

</style>