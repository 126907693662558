export const getters = {
    getBearerToken(state) {
        return state.bearerToken
    },
    getUser(state) {
        return state.user
    },
    getConsoleIndexUrl(state) {
        return state.consoleIndexUrl
    },
    getConsoleFuncList(state) {
        return state.consoleFuncList
    },
    getQuizTitle(state) {
        console.log('store:',state.quizTitle)
        return state.quizTitle
    },
}