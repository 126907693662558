<template>
    <div id="index">
        
    </div>
</template>

<script>
export default {
    name: "indexList",
    components: {},
    data() {
        return {
            
        };
    },
    async created() {
        const consoleIndexUrl = this.$store.getters.consoleIndexUrl
    
        if (consoleIndexUrl != null) {
            this.$router.push(consoleIndexUrl);
        }
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>

<style scoped>
</style>